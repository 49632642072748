* {
  box-sizing: border-box;
}
  .footer {
    margin-top:30px;
    font-family: "Avenir", sans-serif;
    a{
      color:white;
    }
  }
  img.wave {
    margin-bottom: -1px;
    object-fit: cover;
    width: 100%;
  }
  .grayDiv {
    background-color: #424B54;
    height: 350px;
    color: white;
    margin-top: -2.5rem;
    padding-bottom: 4rem;
  }
  .h2footer {
    color: white;
    text-align: center;
    // padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;
    font-family: "Foreday Serif Black", sans-serif;
  }
  .aboutPixyle{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 70%;
    a{
      font-weight: 500;
      font-size: 18px;
    }
    a::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: #fff;
      transition: width .4s ease-in;
    }
    
    a:hover::after {
      width: 100%;
    }
  }
  
  .footer-links{
    width: 80%;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
  .address{
    width: 30%;
    display: flex;
    .logoSmall{
      width: 10%;
      img{
        width: 100%;
      }
    }
    .addressInfo{
      margin-left:1rem ;
      font-size: 500;
      opacity: 60%;
      div{
        margin-top: 1rem;
      }
    }
  }
 .links{
   width: 50%;
   display: -webkit-flex;
   display: flex;
   flex-wrap: wrap;
   -webkit-justify-content: end ;
   justify-content: flex-end ;
   a{
     padding: 0 3.5rem 2rem 3rem;
     font-weight: 800;
     color: #dbdbdb;
     -webkit-transition-duration: 0.4s; 
     transition-duration: 0.4s;
     font-family: "Avenir", sans-serif;
   }
   a:hover{
    color: #fff;
   }
 }
 @media screen and (max-width: 1920px) {
    img.wave {
      margin-bottom: -5px;
    }
    .grayDiv{
      height: 400px;
      padding: 1rem;
      // padding-top: 5rem;
      .aboutPixyle{
          height: 60%;
      }
    }
}
@media screen  and (min-height:1024px){
  .links {
      width: 70%;
  }
}
@media screen and (max-width: 835px) {
  .grayDiv{
    min-height: 450px;
    padding: 1rem;
    .aboutPixyle{
        height: 40%;
    }
    .footer-links{
      padding-top: 5rem;
      border-top: 2px solid rgba(255, 255, 255, 0.26);
      flex-direction: column-reverse;
    }
    .links{
      padding-bottom:1rem ;
      width: 70%;
    }
    .address{
      display: block;
      text-align: center;
       .logoSmall{
        margin: 1rem auto;
      }
      .addressInfo div{
        margin-top: 1.5rem;
      }
    }
  }

} 
@media screen and (max-width: 601px) {
  img.wave {
    margin-bottom: -2px !important;
  }
  .grayDiv{
    min-height: 650px;
    padding: 1rem;
    .aboutPixyle{
        height: 35%;
    }
  }
  .links , .address{
    width: 80% !important;
  }
  .address{
    margin: 2rem;
  }
  
}
@media screen and (max-width: 615px) {
  .grayDiv {
    margin-top: -1.5rem;
    min-height: 680px;

  }
  .grayDiv .aboutPixyle{
    height: 25%;
    margin: 2rem;
    justify-content: center;
  }
  .h2footer{
    font-size: 24px;
  }
  .links, .address{
    width: 100% !important;
  }
  .links{
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
 
}
@media screen and (max-width: 376px) {
  .grayDiv {
    height: 620px;
  }
  .h2footer{
    font-size: 22px;
  }

  .links, .address{
    width: 90% !important;
  }
  img.wave {
    margin-bottom: -0px !important;
  }
}