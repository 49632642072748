*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
              // 16px = 1rem (base)
html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 62.5%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  padding: 0;
 
  @include respond(tab-port) {
    padding: 0rem;
  }
}
::selection {
  background-color: $color-purple;
  color: $color-white;
}

a {
  color: $color-dark-gray;
}

select{
  -moz-appearance: none!important;
  background: transparent!important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")!important;
 -webkit-appearance: none!important;
 background-repeat: no-repeat!important;
    font-size: 1.4rem!important;
    background-position-x: 95%!important;
    background-position-y: 50%!important;
}
html, body{
  max-width: 100vw;
  overflow-x: hidden;
}

.container{
  width: 80%;
  margin: 0 auto;
}
.navbar{
  padding: 2.5rem 0;
}