.modal-class {

    background: #FFFFFF;
    min-height: 40vh;
    /* drop shadow */
    box-shadow: 0.1rem  0.1rem  4rem rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width:85%;
    min-width:110rem;
   
}
.ui.dimmer {
    background-color: rgba(0,0,0,.65);
}
.ui.standard.modal.visible.active.upload-new{
    border-radius: 25px!important;
    // background-image: url(../../assets/upload-modal-background.svg);
    background-size: cover;
    @media only screen and (min-width: 769px){
        width:90rem!important;
        padding:3rem 4rem!important; 
    }
    @media only screen and (max-width: 768px){
        width:80%!important;
        height: 65vh;
        padding:3rem 4rem!important; 
    }
}

.modal-class-error {
    background: #FFFFFF;
    min-height: 40vh;
   
    /* drop shadow */

    box-shadow: 0.1rem  0.1rem  4rem rgba(0, 0, 0, 0.1);
    border-radius: 2rem !important;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width:45% !important;
 
    .content.modal-content{
        border-radius: 2rem !important;    
        // display: flex!important;
        // flex-direction: column;
        // justify-content: center;
        // justify-content: center;
        // align-items: center;
        padding: 4rem;
        min-height: 40vh;
        text-align: center;
        height: 100%;
        text-align: center;
        display: table;

        .centerWithTable{
            display: table-cell;
            vertical-align: middle;
            text-align: center;

        }
        .modal-title{
            // width: 80%;
            word-wrap: break-word !important;
            font-size: 2rem;
            font-weight: 800;
            color: rgba(201, 108, 144, 1);
            font-family: "Avenir-Light", sans-serif;
            padding-top: 2rem;
            .modal-description{
                font-weight: 500;
             color: rgba(201, 108, 144, 1);
             font-size: 2rem;
            }

        }

    }
    // min-width:110rem;
   
}
.modal-description{
    font-weight: 500;
    color: rgba(201, 108, 144, 1);
    font-size: 1.6rem;
    padding: 0.5rem 3rem;
}
.error-modal-image{
    width: 15%;
}
.err{
    border: 2px solid #C96C90 !important;
}
.button-create{
    font-family: "Avenir-Light", sans-serif;
    background: #fff;
    box-shadow: 0.01px 0.1rem 1rem rgba(0, 0, 0, 0.07)!important;
    // border-radius: 0.4rem;
    outline: none;
    border: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius:2rem!important;
    width: 18rem;
    height: 4.1rem;
    margin: 3rem 0.5rem 3rem 2rem;
    cursor: pointer;
    &:hover{
        background: rgba(201, 108, 144, 1);
        color: #FFFFFF;
    }
     &.error-modal-btn{
        background-color: #FFFFFF;
        border: 1px solid rgba(201, 108, 144, 1) ;
        color: rgba(201, 108, 144, 1);
    }
    // &.solution-at{
    //     background-color: $color-at;
    //     &:hover{
    //         background-color: $color-at-hover;
    //     }
    // } 
    // &.solution-vs{
    //     background-color: $color-vs;
    //     &:hover{
    //         background-color: $color-vs-hover;
    //     }
    // } 
    // &.solution-sr{
    //     background-color: $color-sr;
    //     &:hover{
    //         background-color: $color-sr-hover;
    //     }
    // } 
    // &.solution-as{
    //     background-color: $color-as;
    //     &:hover{
    //         background-color: $color-as-hover;
    //     }
    // } 
}


.admin-table.model.select{
    width: 40vw;
    .thead-row{
        width: 300%;
    }
    .model-row.selected{
        // background: $color-blue;
        .td{
            font-weight: 500;
            color:white;
        }
    }
}
.modal-title.select{
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 0;
}
.modal-subtitle{
    display: flex;
    justify-content: center;
    color: #556fb5;
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.wrapper-override{
    padding:0px;
    @media (min-width:769px){
        height:25rem;
    }
    
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:27.5rem;
    border-radius:25px!important;
    
    // align-content: center;

    
    .close-div {
        z-index:999;
        height: 100%;
        width:3rem;
        height:3rem;
        // width: 100%;
        position:absolute;
        margin-left: auto;
        // cursor: pointer;
        // color: $color-medium-gray;
        float:right;

        .icon-modal {
            cursor: pointer;
            margin-left:  2rem;
            margin-bottom: 2rem;
            // color: #424B54;
            position: fixed;
            right: 0.2rem;
            top:0.2rem;
        }
        .icon-modal-new {
            cursor: pointer;
            margin-left:  2rem;
            margin-bottom: 2rem;
            position: fixed;
            right: 1rem;
            top:1rem;
        }
        .icon-modal:hover, .icon-modal-new:hover{
            color: #424B54;
            @extend .transitionEffect;
        }
    }

    .modal-upload-field{
        align-items: center;
        display: flex;
        justify-content: center;
        
        h5{

            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            color: #424B54;
            // padding-top: 40%;
        }
    }

    .modal-delete-field {
        display: flex;
        height:100%;
        width:100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // align-content: center;
        // padding: 2.5rem;


        h5 {
            
            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 140%;
            /* or 38px */
            margin: 3rem 0;
            
            /* Outer space */
            
            color: #424B54;
        }
        h3{
            font-family: "Avenir-Light", sans-serif;
            font-style: normal;
            font-weight: 500;
            text-align: center;
            font-size: 2rem;
            line-height: 160%;
            margin: 3rem 0px;
            color: rgb(66, 75, 84);
        }
        
        .h3-responsive{
            @media (max-width:500px){
                margin: 0px;
            }
        }
       
        
    
        p {
            font-family: "Avenir-Light", sans-serif;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            // align-items: center;
            color: #424B54;
        }
        
        .bottom-text-modal {
       
            // display: flex;
            justify-content: center;
            align-items: center;
            margin-top: auto;
            // margin: 10px 0px;
            color: #424B54;
        }
        

    }
}

// === uplodad img ====
.dimmer > .modal-class-uploadImg{
    height: 50%;
    width: 40%;
    border-radius: 3rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    // background: url(../../assets/upload-modal-background.svg);
    background-size: cover;
        .dropzone{
            width: 90%;
            height: 70%;
        }
        p{
            font-size: 1.6rem;
            color: #8e9aaf;
        }
}
.dimmer > .modal-class-uploadImg .closeModal, .mail-modal .closeModal,  .modal-class-error .closeModal{    
    position: absolute;
    top: 0;
    right: 0;
    margin: 2.5rem;
    
    .close{
        cursor: pointer;
        color:rgba(66, 75, 84, 0.5);
        font-size: 1.45rem;
        &:hover{
            color: #424B54;
            @extend .transitionEffect;
        }
    }
}
// .uploadBox{
//     width: 90%;
//     height: 85%;
//     display: flex !important;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     align-content: center;
//     background-color: white;
//     font-family: "Avenir-Light", sans-serif;
//     box-sizing: border-box;
//     border-radius: 2rem !important;
//     padding: 2rem 0;
//     .uploadButton{
//         width: auto;
//         padding: 0rem 3.5rem;
//         text-transform: capitalize;
//         height: auto;
//         font-size: 1.6rem;
//     }
//     .dropArea{
//         width: 100%;
//         height: 90%;
//         box-sizing: border-box;
//         display: flex;
//         align-items: center;
//         justify-content: center;
        
//         border-radius: 3rem !important;
//         border: 0.3rem dashed $color-at;
        
//         &.at-drop{
//             color: $color-at;
//           }
//         &.vs-drop{
//           color: $color-vs;
//           border: 0.3rem dashed $color-vs;
//         }
//         &.as-drop{
//             color: $color-as;
//             border: 0.3rem dashed $color-as;
//         }
//         .drop-content{
//             width: 50%;
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//             text-align: center;
//             padding: 1.5rem;
            
//         }
//         h5{
//             font-size: 1.4rem;
//             font-family: "Avenir-Light";
//             margin-top: 0.5rem;
//             color: black;
           
//         } 
//         p{
//             font-size: 1.9rem;
//             padding: 1.5rem 0;
//         }
//     }
    
//         .drop {
//             cursor: grabbing;
//             background-color: #DDE2F0;
//             border: 0.3rem dashed $color-at;
//             transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
        
           
//             &.vs{
//                 background-color: #DCDAEB;
//                 border: 0.3rem dashed $color-vs;
//                 transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
//             }
//             &.as{
//                 background-color: #DCDAEB;
//                 border: 0.3rem dashed $color-as;
//                 transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
//             }

//             svg path.svgDrop{
//                 fill: #DCDAEB !important;
//             }
            
//         }
//     h5{
//         font-size: 1.6rem;
//     }
   
// }


.dropArea{
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 3rem !important;
    // border: 0.3rem dashed $color-at;
    
    &.at-drop{
        color: $color-at;
      }
    &.vs-drop{
      color: $color-vs;
      border: 0.3rem dashed $color-vs;
    }
    &.as-drop{
        color: $color-as;
        border: 0.3rem dashed $color-as;
    }
    .drop-content{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1.5rem;
        
    }
    h5{
        font-size: 1.4rem;
        font-family: "Avenir-Light";
        margin-top: 0.5rem;
        color: black;
       
    } 
    p{
        font-size: 1.9rem;
        padding: 1.5rem 0;
    }
}

    .drop {
        cursor: grabbing;
       // background-color:rgba(85, 112, 184, 0.8);
        border: 2px dashed $color-at;
        //transition: background-color 400ms ease-in-out, border 400ms ease-in-out;
        z-index: 55555;
       

        // svg path.svgDrop{
        //     fill: #DCDAEB !important;
        // }
        
    }
    .comleted{
        width: 100%;
    }
    .progress {
        height: 100%;
        border-radius: 1rem;
        width: 100%;
    }
// Animation on Upload

// .showAnime{
//     width: 90%;
//     border-radius: 2rem;
//     padding: 1rem 2rem;
//     margin: 0rem auto;
//     margin-top: 1rem;
//     height: 7rem;
//     border: 0.2rem solid rgba(66, 75, 84, 0.2);
//     display: none;
//     .content-box{
//         height: 80%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 1rem;
//         padding-left: 0.2rem;
//         h3{
//             margin: 0;
//             font-size: 1.35rem;
//         }
//         .anime-icons .circle{
//             font-size: 2rem;
//             &.at{
//                 color: $color-at;
//               }
//             &.vs{
//               color: $color-vs;
//             }
//             &.as{
//                 color: $color-as;
//             }
//         }
//     }
// }
// .show-progress{
//     display: block;
//     opacity:1;
//     -webkit-animation-name: fadeIn;
//     -webkit-animation-duration: .5s;
//     animation-name: fadeIn;
//     animation-duration: .5s;
// }
// @keyframes fadeIn {
//     0% { opacity: 0; }
//     20% { opacity: 0; }
//     40% { opacity: 0.3; }
//     60% { opacity: 0.5; }
//     80% { opacity: 0.9; }
//     100% { opacity: 1; }
//     }

// .progress-div {
//     // border: 0.1rem solid rgba(66, 75, 84, 0.2) ;
//     border-radius: 2rem;
//     height: 0.85rem;
//     width: 100%;
//     transition: 0.8s linear;  
//     transition-property: width, background-color;
//     position: relative;
// }
// .progress {
//     height: 100%;
//     border-radius: 1rem;
//     width: 100%;
//     animation: colorAnimation 1.4s infinite;   
//     animation-fill-mode: forwards;
//     animation-timing-function: linear;
//     &.at{
//         background: repeating-linear-gradient(to right, $color-at 0%,$color-at-hover 25%, $color-light-gray 50%, $color-at-hover 75%, $color-at  100%);
//         background-size: 200% auto;
//         background-position: 0 100%;
//     }

//   }
//   @keyframes colorAnimation {
//         0%   { background-position: 0 0; }
//         100% { background-position: -200% 0; }
//   }

// .uploading-process{
//     font-family: "Avenir-Light", sans-serif;
// }
// .iconPopUp{
//     max-height: 75%;
//     padding-top: 1rem;
//     svg { max-height: 90%;}
//     path{
//         &.at{
//             fill: $color-at !important;
//           }
//         &.vs{
//           fill: $color-vs !important;
//         }
//         &.as{
//             fill: $color-as !important;
//         }
//     }
// }
.progress-complete{
    border-radius: 1rem;
    height: 0.6rem;
    width: 0;
    // &.at{
        
    //     background-color: $color-at;
    //     animation: completed 0.8s normal forwards;
    //   }
    // &.vs{
    //     background-color: $color-vs;
    //   animation: completed 0.8s normal forwards;
    // }
    // &.as{
    //     background-color: $color-as;
    //    animation: completed 0.8s normal forwards;
    // }
}

.completed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // svg {
    //     width: 40%;
    //     path {
    //         &.at{
    //             fill: $color-at !important;
    //         }
    //         &.vs{
    //         fill: $color-vs !important;
    //         }
    //         &.as{
    //             fill: $color-as !important;
    //         }
    //     }
    // }
     
    h5{
        padding-top: 2.5rem;
        font-family: "Avenir-Light", sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        // span{
        //     &.at{
        //         color: $color-at;
        //       }
        //     &.vs{
        //       color: $color-vs;
        //     }
        //     &.as{
        //         color: $color-as;
        //     }
        // }
    }
}

// Loading
.ui.modal.modal-class-loading {
    height: 50%;
    width: 40%;
    border-radius: 3rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    // background: url(../../assets/upload-modal-background.svg);
    background-size: cover;

    }
    .imgDiv{
        max-width: 100%;
        height: 55%;
        display: flex;
        justify-content: center;
        img{
           padding:0 1rem;
            max-width: 100%;
            object-fit: contain}
        }
        @keyframes completed {
            0% { width: 0; }
            100% { width: 100%; }
        } 

//////////////////////MEDIA QUERIES /////////////////////
// @media screen and (max-width: 1110px){
 
// }
@media screen and (max-width:835px){
    .dropzone{
        width: 100%;
    }
}

@media screen and (max-width:569px){
    
    // .ui.modal.modal-class-loading {
    //     width: 80%;
    //     height: 45%;
    // }
    // .uploadBox .dropArea{
    //     border: none;
    // }
    // .dimmer > .modal-class-uploadImg .dropzone{
    //     width: 100%;
    // }
    // .dimmer > .modal-class-uploadImg {
    //     width: 80%;
    // }
    // .completed h5 {
    //     padding-top: 0rem;
    //     font-family: "Avenir-Light", sans-serif;
    //     font-weight: 700;
    //     font-size: 1.5rem;
    //     width: 60%;
    //     text-align: center;
    // }
    // .ui.small.image, .ui.small.images .image, .ui.small.images img, .ui.small.images svg {
    //     width: 130px
    // }
}
@media screen and (max-width: 768px) {
    .modal-class-error{
        width: 80vw !important;
        .modal-content{
            display: flex;
            flex-direction: column;
            align-items: center;
           
            justify-content: space-between;
            height: 50vh;
            .modal-title{
                padding-top: 13vh;
            }
        }
    }
}
@media screen and (max-width: 320px) and (min-width: 301px){
.ui.dimmer.modals.visible.active {
    width: 100vw !important; 
    height: 100vh !important; 
}}