body {
    font-family: "Avenir-Light", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    color: $color-dark-gray;
  }
  
  .heading-primary {
    text-transform: capitalize;
    font-size: $large-font-size;
    &--bold {
      font-weight: 800;
    }
  }
  
  .heading-secondary {
    text-transform: capitalize;
    font-weight: 500;
    font-size: $default-font-size;
  }
  
  .heading-tertiary {
    text-transform: capitalize;
    font-size: $small-font-size;
    color: $color-light-gray;
    font-weight: 100;
  }
  
  .transitionEffect{
    transition: 0.3s;
    transition-timing-function: ease;
  }

