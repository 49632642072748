//COLORS
$color-purple: #52489c; // za visual search

$color-at: #556fb5; // za autotag
$color-at-hover: #4A65AF;

$color-sr: #00bfb2; // za sim. recommendations 
$color-sr-hover:#00b4a7;

$color-vs:#52489C; // za visual search
$color-vs-hover: #392F86;

$color-sr: #00bfb2; // za sim. recommendations 
$color-sr-hover:#00b4a7;
//auto scale
$color-as: #E9789E;
$color-as-hover: #F05F90;
$color-as-acs:#D2537E;

$color-cherry: #db2763;
$color-yellow: #ffe74c;
$color-dark-gray: #424b54;
$color-medium-gray: #8e9aaf;
$color-medium-light-gray:#979EAD;
$color-light-gray: #c7ccd7;
$color-white-gray: #FBFCFF;
$color-off-white: #f6f6fa;
$color-white: #fff;
$color-black: #1a1423;

$color-input: #E5E5E5;
$color-placeholder: #9A9696;
//
$content-width: 125rem;


//FONT
$small-font-size: 1.2rem;
$medium-font-size: 1.4rem;
$default-font-size: 1.6rem;
$large-font-size: 2rem;
$big-font-size: 2.4rem;

//SHADOWS
$small-shadow-light: 0px 0.2rem 0.8rem rgba(0, 0, 0, 0.05);
$big-shadow-light: 0.1rem 0.1rem 4rem rgba(0, 0, 0, 0.1);
$big-shadow-dark: 0.1rem 0.1rem 4rem rgba(0, 0, 0, 0.23);


//additional

$border-radius: 4px;
$card-shadow: 0px 0px 28px rgba(0,0,0,0.08)