.grid-section {
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    margin: 5rem auto;
    padding: 1em 1em;
    border-radius:2rem;
    border: 1px solid rgba(34,36,38,.15);
    min-height: 25rem;
}
.ui.grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0;
}
.ui.grid > .column.img {
    width: 40%;
    padding-right: 2.5rem;
}
.ui.grid > .column.attr-ld {
    width: 60%;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    margin-right: 0 !important;
    padding: 1rem 0 0 0 !important;
}
.direction-row {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 17px;
    max-width: 100%;
    flex-wrap: wrap;
    padding-top: 2rem;
}
.attributeTabLeft {
    border-radius: 1.7rem;
    display: flex;
    height: 100%;
    width: 35%;
    position: relative;
}
.attr.ld{
    padding-right: 2rem;
}
.direction-row.maxwidth {
    max-width: 60%;
}
.parahraph-attrType {
    text-transform: capitalize;
    font-size: 1.7rem;
    // padding-top: 1rem;
    margin-right: 0.5rem;
    color: #7C7C7C;
}
.gridImage {
    border-radius: 2rem;
    display: block;
    height: 100%;
    top: 50%;
    width: 100%;
}
.noLabelTags{
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}