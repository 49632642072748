.home{
    padding: 2rem 0;
}
// UPLOAD
.uploadContainer{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
.upload{
    border: 2px dashed #E1E1E1;
    border-radius: 4rem;
    padding: 5rem auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // margin: 2rem 0;
    padding: 2rem;
    &.at{
        width: 75%;
    }
    position: relative;
}
.columnUploadedImageText{
    margin: 2rem;
}
.columnUploadedImageInput {
    height:4.5rem;
    text-align: start!important;
    display: flex;
    position: relative;
    width: 85%;
}
.columnUploadedImageButton {
    background: #556fb5;
    border-radius: 3rem;
    color: #fff;
    cursor: pointer;
    float: left;
    font-family: Avenir-Light, sans-serif;
    font-size: 1.6rem;
    // font-weight: 900;
    height: 4.5rem;
    line-height: 4.5rem;
    text-align: left;
    text-align: center;
    transition-duration: .4s;
    width: 19.5rem;
}
.inputfile {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
}
.upload-icon {
    height: 40%;
    padding: 0.1rem 0.5rem;
}
.inputLink {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2rem;
    font-size: 1.7rem;
    outline: none;
    padding: 1rem;
}
.columnUploadedImageInput .inputLink {
    background-color: #f2f2f2;
    padding-left: 2rem;
    text-align: start;
    width: 100%;
}
input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
}
.columnUploadedImageInput .urlButtonAutomatic {
    height: 4.5rem;
    position: absolute;
    right: -1px;
    width: 15.5rem;
    background: #fff;
    border: 2px solid rgba(85,112,184,.12);
    border-radius: 2.5rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 900;
    margin-bottom: 0;
    margin-left: 1.5rem;
    outline: none;
    text-align: center;
    transition-duration: .4s;
    color: #556fb5;
    font-family: Avenir-Light,sans-serif;
}
.hideComa{
    display: none;
}
.urlButtonAutomatic:hover {
    background: #4a65af;
    color: #fff;
}
// upload progress
.uploadingProgress{
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.progress{
    width: 90%;
    height: 2.5rem;
    margin: 2rem auto;
    background-color: $color-input;
}
.uploadMsg{
    padding: 1rem;
    font-size: 16px;
    color: $color-at;
    font-weight: 700;
}
.completed-progress {
    animation: load 1.2s normal forwards;
    box-shadow: 0 10px 40px -10px $color-at;
    border-radius: 100px;
    background: $color-at;
    height: 2.5rem;
    width: 0;
  }
  
  @keyframes load {
    0% { width: 0; }
    100% { width: 100%; }
  }
  .show {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.hide {
    display: none;
}
.upload.at .show{
    justify-content: center !important;
    align-items: center !important;
}
.comleted{
    width: 100%;
}
.progress {
    height: 100%;
    border-radius: 1rem;
    width: 100%;
}
